@import '../../css/includes/variables';

.date-picker-popover {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);

  * {
    font-family: 'Lato';
    outline: none;
  }

  .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
    background-color: $color2;
  }

  .bp3-daterangepicker .DayPicker-Day--selected-range {
    background-color: $color1;
  }

  .bp3-datepicker .DayPicker-Day:hover,
  .bp3-datepicker .DayPicker-Day:focus {
    background-color: $color2;
    color: #ffffff;
  }

  .bp3-daterangepicker .DayPicker:first-of-type .bp3-datepicker-navbar {
    justify-content: space-between;
    left: 0;

    .bp3-button.bp3-minimal:hover {
      background-color: $color1;
    }

    & > button {
      width: 30px;
      height: 30px;
    }
  }
}
