@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';
.responses-list {
  .response-item {
    display: flex;
    align-items: stretch;

    margin: $spacing-xxs 0;
    .input-form-group,
    .textarea-form-group {
      margin: 0 10px;
    }

    .response-description {
      flex-basis: 100%;
    }
    .response-item-controls {
      display: flex;
      align-items: baseline;
      margin-left: $spacing-xxs;
      margin-top: 30px;
      color: $color2;
      cursor: pointer;
    }
  }
}

.responses-list-footer {
  display: flex;
  justify-content: flex-end;
  .button-custom {
    margin-right: 30px;
  }
}
