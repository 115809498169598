@import 'variables';

input[type='text'],
input[type='password'],
input[type='search'] {
  border: 1px solid $color2;
  background-color: #fcfcfc;
  height: 35px;
  padding: 0 21px;
  border-radius: 10px;
  outline: none;
  box-shadow: none;

  font-size: 14px;
  color: $black-font;
  font-family: 'Lato';
  font-weight: 300;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    color: $black-font;
    font-family: 'Lato';
    font-weight: 300;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
    color: $black-font;
    font-family: 'Lato';
    font-weight: 300;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
    color: $black-font;
    font-family: 'Lato';
    font-weight: 300;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
    color: $black-font;
    font-family: 'Lato';
    font-weight: 300;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.bp3-input-group {
  position: relative;
  .bp3-icon-search {
    color: $color2;
    position: absolute;
    right: 10px;
    left: auto !important;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
  }

  & > .bp3-input-action {
    position: absolute;
    top: 50%;
    right: 18px !important;
    transform: translateY(-45%);
  }
}

// Select / popover
.bp3-popover-wrapper {
  width: 100%;

  .bp3-popover-target {
    width: 100%;

    .bp3-button {
      width: 100%;
      height: 35px;
      border-radius: 10px;
      background: $color2;
      color: white;
      box-shadow: 0px 3px 6px $shadow;

      .bp3-icon {
        color: white;
      }
    }
  }
}
.bp3-popover-content {
  .bp3-input-group {
    .bp3-input-action {
      right: 17px !important;
      background-color: #fff;
      z-index: 1;
      .bp3-button {
        margin: 0;
        height: 100%;
      }
    }
  }
}

// btns
.bp3-button {
  width: 100%;
  background-color: $color2;
  color: #ffffff;
  border-color: $color2;
  border-radius: 10px;
  height: 35px;
  background-image: none;
  transition: all 0.4s;
  outline: none;

  font-family: 'Lato';
  font-weight: 700;

  &:hover {
    background-color: shade($color2, 30);
    box-shadow: 2px 1px 2px gray;
  }
}

.bp3-input-action {
  .clear-input {
    width: 24px !important;
    height: 24px !important;
    padding: 4px;
    border-radius: 10px;
    background-color: #ffffff !important;
    box-shadow: none !important;
    svg {
      path {
        fill: #5c7080;
      }
    }
    &:hover {
      background: #ebf1f5 !important;
    }
  }
}
