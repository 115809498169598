@import 'src/css/includes/variables';

.tags-input {
  position: relative;
  border: 1px solid $color2;
  background-color: #fcfcfc;
  min-height: 35px;
  padding: 0 21px;
  border-radius: $border-radius;
  outline: none;
  box-shadow: none !important;

  font-size: 14px;
  color: $black-font;
  font-family: 'Lato';
  font-weight: 300;
  .bp3-tag {
    min-height: unset;
    padding: 2.5px 15px;
  }
}
