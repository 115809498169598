.image-picker {
  // Label
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  z-index: 5;

  > input {
    display: none;
  }

  > span {
    // Button
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translate(-50%, 50px);
    opacity: 0;
    cursor: pointer;

    transition: all 0.4s ease;

    svg path {
      // Button Icon
      stroke: #ffffff;
    }
  }

  &:hover {
    & > span {
      opacity: 1;
      transform: translate(-50%, -50%);
      background-color: #54ada890;
    }
  }
}
