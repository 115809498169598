.userForm {
  .iconAccount {
    position: absolute;
    left: -280px;
    bottom: 0;
    width: 65%;
  }

  .background-picture {
    text-align: left;
    left: -90%;
    top: -250px;
    overflow: visible;

    img {
      position: absolute;
      left: -30%;
      top: 10%;
    }
  }

  .language-selector {
    width: 184px;
  }
}
