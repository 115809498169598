@import 'src/css/includes/variables';
.homepage-container {
  .sidebar {
    z-index: 8;
    padding: 40px 40px 0 40px;
    box-shadow: 4px 3px 6px $shadow;
    min-width: 350px;
    margin-bottom: -15px;
    .title-wrapper h2 {
      text-align: center;
      font-size: 25px;
      white-space: nowrap;
    }
    hr {
      border-color: $color2;
      margin-top: 0px;
      //margin-left: 20px;
      margin-right: 60px;
    }
  }

  .main {
    padding-left: 0;
    margin-top: 40px;

    > div {
      box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);
      border-radius: 0 10px 10px 0;
    }
  }
  & > .margin-top {
    margin-top: 15px;
  }
  .custom-tabs.bp3-tabs > .bp3-tab-panel {
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
  }
}

.companies-sidebar {
  background-color: #ffffff;
  border-radius: 10px;
}
