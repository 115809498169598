@import '../../../../css/includes/spacing';
@import '../../../../css/includes/variables';
.connector-request-teaser {
  padding: $spacing-xxs;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;

  &.active {
    background-color: #f5f8fa;
  }

  .request-name {
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.2s ease;
  }
  &.active,
  &:hover {
    .request-name {
      color: $color2;
    }
  }
}
.connector-test {
  padding-left: 0;
  .custom-card {
    border-radius: 0px 10px 10px 0;
  }
}
.connector-test-wrp {
  display: flex;
  align-items: center;
}
.endpoint-teaser {
  margin: $spacing-xs 0;
  .collapse-header {
    border-bottom: 1px solid #ebf1f5;
  }
  .bp3-collapse-body {
    border-bottom: 1px solid #ebf1f5;
  }
  .endpoint-teaser-title {
    display: block;
    font-weight: bold;
    font-family: $header-font;
    color: $black-font;
    font-size: 15px;
    padding-top: $spacing-xxs/2;
    padding-bottom: $spacing-xxs/2;
    transition: color 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: $color2;
    }
  }

  &.active {
    .endpoint-teaser-title {
      color: $color2;
    }
  }
}

.request-type-tag {
  margin: 5px;
}

.connector-authorization-button {
  width: max-content;
}
.connector-test {
  .connector-section-title {
    justify-content: space-between;
  }
  .custom-tabs {
    .bp3-tab-panel {
      padding: 0;
      box-shadow: none;
    }
  }
}
