.cardCompany {
  //width: 100%;
  margin: $spacing;
  margin-left: $spacing;
  margin-right: $spacing;
  margin-top: $spacing-lg;
  box-shadow: 1px 1px 4px $color2;
  border-radius: 10px;
  border: 1px solid $color2;

  .cardPicture {
    position: relative;

    img {
      position: absolute;
      bottom: -20px;
      right: 40px;
      width: 40%;
      box-shadow: 8px 10px 11px $color2;
    }
  }

  .btnCompEdit {
    width: 79px;
    height: 35px;
    min-width: unset;
  }
}
