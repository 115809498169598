@import '../../css/includes/variables';
@import '../../css/includes/spacing';

.settings-page-container {
  H2 {
    margin-bottom: $spacing-xs;
    padding: 0 $spacing;
  }

  .bp3-vertical {
    background: white;
    padding: $spacing;
    border-radius: 10px;
    box-shadow: 0 3px 6px $shadow;
    min-height: 590px;

    > .bp3-tab-list {
      padding-top: 50px;
      width: 150px;

      .bp3-tab-indicator-wrapper {
        background-color: white;
        border: 2px solid $color2;
        border-right: none;
        border-radius: 30px 0 0 30px;
        box-shadow: -2px 3px 3.5px rgba(84, 173, 168, 0.3);
        font-family: 'Lato';
        font-weight: 700;
        width: 130px;
        z-index: 10;

        .bp3-tab-indicator {
          position: relative;
          background-color: white;
          outline: none;
          transform: translateX(-3px);

          &:after {
            content: '';
            position: absolute;
            top: -18px;
            width: 20px;
            height: 78px;
            right: -4px;
            background-size: contain;
            background-position: 4px 0;
            background-repeat: no-repeat;
            background-image: url('../../images/tabsCursorIcon.svg');
          }
        }
      }

      .bp3-tab {
        padding: 10px 10px 10px 25px;
        outline: none;

        &[aria-selected='true'] {
          font-weight: bold;
          color: $color2;
          z-index: 15;
          overflow: initial;
        }
      }
    }

    > .bp3-tab-panel {
      padding-left: 0;
      position: relative;
      border: 1px none $color2;
      border-radius: 10px;
      box-shadow: 0 0 4px $shadow;
      min-height: 552px;
    }
  }
}

.tab-form {
  padding: $spacing $spacing-xs $spacing $spacing;
  box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.45);
  border-radius: 10px;
  height: 100%;

  h4 {
    margin: 0 0 20px 0;
    font-size: 16px;
  }

  .tab-form-divider {
    position: absolute;
    top: 15px;
    left: -1px;
    bottom: 15px;
    z-index: 5;
    width: 2px;
    background-color: $color2;
  }
  .track-horizontal {
    display: none;
  }
}
