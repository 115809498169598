@import 'src/css/includes/variables';

.containComp {
  border: 1px solid $color2;
  padding: $spacing 0;
  border-radius: 15px;

  input[type='text'] {
    width: 60%;
    position: relative;
  }

  .bp3-button:not([class*='bp3-intent-']) {
    color: #ffffff;
    float: right;
    background-color: $color2;
    background-image: none;
    height: 50px;

    span {
      color: #ffffff;
    }
  }

  .compIntegration {
    margin-top: $spacing;
    margin-left: $spacing-xs;
    margin-right: $spacing-xs;
  }
}

.companyListShort-element-wrap * {
  margin-left: 5px;
}

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
  background-color: $color2;
}

.bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
  background-color: $color2;
}

.company {
  &.teaser {
    position: relative;
    height: max-content;
    transition: all 0.4s;
    padding: 5px 0;
    cursor: pointer;

    .wrapper {
      display: flex;
      position: relative;
      padding-right: 15%;
      align-items: center;
      height: 50px;

      .company-logo {
        margin-right: $spacing-xxs;
        margin-left: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        img {
          margin-left: 0;
          width: 32px;
          height: 32px;
        }
      }

      .integrations-count {
        position: absolute;
        right: 0;
        display: inline-block;
        min-width: 15%;
        text-align: center;
        background: transparent;
        border: 1px solid $color2;
        border-radius: 10px;
        color: $color2;
        transition: all 0.4s;
      }
    }

    &.selected {
      .wrapper {
        .integrations-count {
          background: $color2;
          color: white;
        }

        .company-title {
          font-weight: 700;
        }
      }
    }

    &:hover {
      .company-nid {
        opacity: 1;
      }
    }

    .company-nid {
      font-size: 0.8em;
      font-weight: bold;
      color: #54ada8;
      position: absolute;
      top: 0;
      left: 45px;
      opacity: 0.1;
      transition: all 0.4s;
    }
  }

  &.medium {
    margin: $spacing 0 $spacing-md;
    padding: 0;

    & > .row {
      padding: 20px;
    }
    .company-logo {
      margin-top: -50%;
    }

    &:hover {
      box-shadow: 1px 1px 4px #54ada8;
    }

    span {
      display: block;
    }

    .company-title {
      font-size: 16px;
      font-family: $header-font;
      font-weight: 700;
      margin-left: -20px;
    }

    .company-integrations {
      font-size: 12px;
      color: #54ada8;
      margin: 12px 0 0 0;
      font-family: 'Lato';
      font-weight: 300;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 1px;
        background-color: $color2;
        top: calc(50% - 1px);
        left: 0;
        transform: translateX(-170%);
      }
    }
  }

  &.edit {
    .country-select {
      width: 189px;
    }

    .buttons-group {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 60px;
      z-index: 5;
      transform: translate(0, 50%);
    }

    .iconAccount {
      position: absolute;
      bottom: 0;
      left: -85%;
      width: 65%;
    }

    .company-logo {
      border-radius: 50%;
      overflow: hidden;
      width: 90px;
      height: 90px;
      box-shadow: 5px 2px 5px rgba($color2, 0.3);
      display: flex;
    }

    .background-picture {
      text-align: left;
      right: 300px;
      top: -250px;
      overflow: visible;

      img {
        position: absolute;
        left: -30%;
        top: 10%;
      }
    }
  }
}

.company-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
