@import '../../../css/includes/variables';
@import '../../../css/includes/card';
@import 'src/css/includes/spacing';

.dashboard-content-container {
  width: 1170px;
  margin: 0 auto;
  position: relative;

  .connectors-middle {
    display: flex;
    align-items: center;
    align-content: center;
    height: 140px;
    justify-content: center;
  }

  .connector-name {
    font-size: 34px;
    color: #303030;
    font-family: $header-font;
    font-weight: 700;
    margin-bottom: $spacing;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .back-button {
    margin-left: 15px;
    background-image: none;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    svg {
      fill: #54ada8;
    }
  }

  .config-button-container {
    width: max-content;
    padding: 5px;
  }
  .panel-container {
    margin-bottom: 35px;
    .panel-card {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .dropdown {
    width: max-content;
    & > .bp3-popover-target {
      width: max-content;
    }
    & > .bp3-popover-target > div > button {
      width: 161px;
      height: 35px;
      border-radius: 5px;
      background-color: #ffffff;
      border: 0.5px solid #54ada8;
      padding-right: 0;
      box-shadow: none;
      transition: opacity 0.4s ease;

      & > span {
        font-size: 14px;
        color: #808080;
        width: 100%;
        font-family: 'Lato';
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 35px;
        margin: 0;
        border-right: 1px solid $color2;
      }

      & > .arrow-icon {
        width: 35px;
        svg {
          width: 35px;
          display: block;

          path {
            fill: $color2;
          }
        }
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  .bp3-popover-wrapper {
    width: max-content;

    .date-picker-target {
      width: max-content;

      button {
        width: 261px;
        height: 35px;
        border-radius: 5px;
        background-color: #ffffff;
        border: 0.5px solid #54ada8;
        display: flex;
        padding-left: 18px;
        align-items: center;
        justify-content: flex-start;
        box-shadow: none;

        & > span {
          font-size: 14px;
          color: #808080;
          font-family: 'Lato';
          font-weight: 300;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .bp3-icon {
          width: 30px;
          margin-left: auto;

          svg {
            margin: 0 0 0 auto;

            path {
              fill: #808080;
            }
          }
        }
      }
    }
  }

  .editor-row {
    .bp3-popover-wrapper {
      width: 100%;
    }
  }

  .action-button {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 0.5px solid #54ada8;
    box-shadow: none;

    svg path {
      fill: #808080;
    }
  }
}

.shadow-card {
  margin: 0;
  @include shadow-card;
}

.table-row {
  .table-row-actions {
    opacity: 0.1;
    transition: opacity 0.4s ease;
    svg path {
      fill: $color2;
    }
  }
  &:hover {
    .table-row-actions {
      opacity: 1;
    }
  }
}
@media (max-width: 885px) {
  .table-row {
    .table-row-actions {
      opacity: 1;
    }
  }
}
