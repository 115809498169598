@import '../../../css/global';
@import '../../../css/includes/form';

.custom-control-label {
  padding-left: $spacing-xs;
  font-size: 14px;
  color: $black-font;
  font-family: 'Lato';
  font-weight: 300;
}

.custom-checkbox {
  margin-bottom: 40px;
}

.no-account {
  margin: 43px 0 0 0;

  > a {
    color: $color2;
    font-weight: 700;
  }
}

.forgot-password {
  font-weight: bold;
}
