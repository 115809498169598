@import 'src/css/includes/_variables.scss';

.chart-container {
  border-radius: 10px;
  box-shadow: 0 3px 3.5px $shadow;
  background-color: #ffffff;
  color: inherit;
  padding: 20px;
  height: 100%;
}
