@import '../../css/includes/variables';
@import '../../css/includes/spacing';
@import '../generic-selector/styles';

.dynamic-table {
  padding: 0 30px;
  .checkbox-styles {
    margin-bottom: 0;
  }
  .table-rows {
    margin-left: 0;
    margin-right: 0;
  }

  .table-header {
    display: flex;
    align-items: center;
    position: relative;
    .bp3-popover-wrapper {
      position: absolute;
      right: 15px;
      top: 0;
    }
    .bp3-popover-target {
      display: inline-block;
      width: 35px;
      .table-fiters-button {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background-color: #ffffff;
        border: 0.5px solid $color2;
        box-shadow: none;
        &.active {
          background-color: $color2;
          svg path {
            fill: #fff;
          }
        }
      }
    }
    .bp3-popover-target {
      .select-all-button {
        display: inline-block;
        background: none;
        border: none;
        box-shadow: none;
        color: $color2;
        min-width: max-content;
        margin: 0;
        padding: 0;
        &.bp3-disabled {
          color: $gray-font;
        }
      }
    }
  }
  .table-checkbox {
    display: flex;
    overflow: visible;
    align-items: center;
    padding: 0px;
    width: 58px;
    .checkbox-form-group {
      margin-bottom: 0;
    }

    .checkbox-styles {
      margin-bottom: 0;
    }
    .bp3-popover-wrapper {
      position: static;
    }
  }
  .table-rows {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .table-row {
    display: flex;
    width: 100%;
    border-bottom: 2px solid #54ada875;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    overflow-wrap: break-word;
    padding: 10px;

    .table-row-actions {
      position: absolute;
      right: 10px;
      opacity: 0.1;
      transition: opacity 0.4s ease;
      width: 60px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      svg path {
        fill: $color2;
      }
    }
    &:hover {
      .table-row-actions {
        opacity: 1;
      }
    }
    .table-row-menu-icon {
      margin-left: 10px;
      transform: rotate(90deg);
      svg path {
        fill: $color2;
      }
    }
  }

  @media (max-width: 885px) {
    .table-row {
      .table-row-actions {
        opacity: 1;
      }
    }
  }
}

.table-row-popover {
  @include dropdown-list;
  border: none;
  box-shadow: none;
}

.table-filters-popover {
  .bp3-popover-content {
    .table-filters-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        margin: 0 5px;
      }
    }
    .table-filters-buttons {
      margin-top: $spacing-xs;
      display: flex;
      justify-content: flex-end;
      & > .button-custom:first-child {
        margin-right: 5px;
      }
    }
    margin: 0;
    padding: 10px $spacing-xs;
  }
}

.table-header {
  color: #303030;
  font-family: $header-font;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  margin: 0;
  padding: 10px;
}

.table-header-title {
  display: flex;
  align-items: center;
  & > span {
    display: block;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    .table-sort-label {
      opacity: 1;
    }
  }
  .table-sort-label {
    opacity: 0.5;
    transition: opacity 0.4s ease;
    & svg path {
      transition: all 0.4s ease;
      fill: #ced9e0;
    }
    .arrow-icon {
      margin: 5px;
    }
    &:first-of-type {
      .arrow-icon {
        transform: rotate(180deg);
      }
    }

    &.active {
      opacity: 1;
    }
    &.active {
      .arrow-icon {
        svg path {
          fill: $color2;
        }
      }
    }
  }
}
