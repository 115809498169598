@import '../../css/includes/variables';

.dropdown {
  .dropdown-button {
    height: 35px;
    padding: 0 17px;
    .arrow-icon {
      margin-left: auto;

      svg path {
        fill: #ffffff;
      }
    }
  }
}
@mixin dropdown-list {
  .bp3-popover-content {
    background: none;

    .bp3-menu {
      margin-top: 5px;
      border-radius: 11px;
      padding: 0;
      overflow: hidden;
      box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);

      li > a {
        padding: 6px;
        border-radius: 0;

        &.bp3-active {
          background-color: $color2;
          color: #ffffff;

          &:hover {
            background-color: $color2;
            color: #ffffff;
          }
        }

        &:hover {
          background-color: $color2-secondary;
        }
      }
      .custom-scrollbars {
        & > div:first-of-type {
          padding: 0;
        }
        .track-vertical {
          top: 2.5px;
          bottom: 2.5px;
          right: 2.5px;
          height: calc(100% - 5px);
        }
      }
    }
  }

  .bp3-popover-arrow {
    display: none;
  }
}

.bp3-select-popover,
.bp3-multi-select-popover {
  border: none;
  box-shadow: none;
  @include dropdown-list;
}

.selected-tags-input {
  position: relative;
  border: 1px solid $color2;
  background-color: #fcfcfc;
  min-height: 35px;
  padding: 0 21px;
  border-radius: $border-radius;
  outline: none;
  box-shadow: none !important;

  font-size: 14px;
  color: $black-font;
  font-family: 'Lato';
  font-weight: 300;
  .bp3-tag {
    background-color: $color2;
    color: #ffffff;
    font-weight: bold;
  }
  .arrow-icon {
    position: absolute;
    right: 21px;
  }
}
