.toaster-custom {
  & > div > .bottom-center {
    width: 550px;
    margin-left: -275px;
    .rrt-success {
      background-color: #0f9c68;
    }
    .rrt-error {
      background-color: #c23030;
    }
  }
}
