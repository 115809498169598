@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';
.endpoint-teaser {
  &.--small {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    .endpoint-teaser-title {
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .endpoint-icon {
      margin-right: 5px;
    }
  }
}
