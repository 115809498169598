@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';

.main-row-connectors {
  height: calc(100% - 68px);
}

.connector-form {
  position: relative;
  width: 100%;
  .form-actions {
    transform: translateY(0);
  }
  .connector-wrp {
    display: flex;
    align-items: center;
  }
  .connector-form-wrp {
    padding-left: 0;
    .custom-card {
      border-radius: 0px 10px 10px 0;
    }
  }
  .connector-menu-link {
    display: block;
    font-weight: bold;
    font-family: $header-font;
    color: $black-font;
    font-size: 15px;
    padding-top: $spacing-xxs/2;
    padding-bottom: $spacing-xxs/2;
    transition: color 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.active,
    &:hover {
      color: $color2;
    }
  }
}
.connector-form-section {
  padding: $spacing-xs 0;
  &.--border {
    border-top: 1px solid #bfd4d6;
    &:last-child {
      border-bottom: 1px solid #bfd4d6;
    }
  }
}
.non-ideal-state-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
}

.connector-section-title {
  display: flex;
  align-items: center;
  margin-top: $spacing-xxs;
  margin-bottom: $spacing-sm;
  text-overflow: ellipsis;
  .bp3-heading {
    flex-basis: 100%;
    margin-bottom: 0;
  }
  .bp3-icon {
    color: $color2;

    transition: opacity 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .edit-list-icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: transparent;
    padding: 1px;
    &.bp3-icon-plus {
      border: 2px solid #54ada8;
    }
  }
  .bp3-icon.bp3-icon-circle-arrow-left {
    margin-right: 5px;
  }
}
.connector-navigation {
  margin-bottom: $spacing-xxs;

  .connector-navigation-link {
    display: inline-block;
    padding: $spacing-xxs;
    font-weight: bold;
    text-decoration: none;
    color: $color2;
    border-radius: 5px;
    margin: 0 $spacing-xs;
    transition: background-color 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
    &.active {
      background: $color2;
      color: #fff;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.connector-menu {
  padding-right: 0;
  z-index: 1;
  .custom-card {
    height: 100%;
    box-shadow: 4px 3px 6px $shadow;
  }
}

.connector-code-editor {
  .cm-editor {
    height: 55vh;
  }
  box-sizing: content-box;
  &.ace_focus {
    border: 1px solid $color2;
    transition: border 0.3s ease;
  }
  &.invalid {
    border: 1px solid $danger-color;
    transition: border 0.3s ease;
  }
  padding: $spacing-xxs;
  border: 1px solid #fff;
  border-radius: $border-radius;
}
