$color1: #f5fafa;
$color2: #54ada8;
$color2-secondary: #54ada850;
$color3: #f3b055;
$color3-secondary: #fbd5a1;
$gray: #eeeeee;
$shadow: rgba($color2, 0.3);
$danger-color: #f55656;
$succes-color: #0f9c68;

$primary-font: 'Lato', sans-serif;
$header-font: 'Raleway', serif;
$black-font: #303030;
$gray-font: #808080;

$header-size: 10vh;
$footer-size: 7vh;

$border-radius: 10px;
