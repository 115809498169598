@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';

.header-userMenu-dialog * {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-xxs;
}

.header-userMenu-dialog {
  display: inline-grid;

  .userTof {
    padding-bottom: 15px;
  }

  link {
    background: transparent;
    border-bottom: 1px solid $color2;
  }

  .bp3-button.bp3-minimal {
    background: transparent;
  }

  .bp3-button.bp3-minimal:active,
  .bp3-button.bp3-minimal:hover {
    background: transparent;
  }

  hr {
    border-color: $color2;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.dialogMenu {
  width: 540px;
  background-color: #ffffff;
  position: relative;
  color: $black-font;
  font-family: 'Lato';
  font-weight: 400;
  border-radius: 10px;
  margin: 0;
  padding: $spacing;
  box-shadow: 0 3px 6px rgba(84, 173, 168, 0.45);

  &.integration-config {
    width: 50%;
    min-width: 750px;
  }

  .bp3-dialog-body {
    margin: 100px 0 80px 0;
  }

  .menu_link {
    font-size: 20px;
    color: $black-font;
    font-family: 'Lato';
    font-weight: 400;
    text-align: center;
    text-decoration: none;

    margin-top: 35px;

    &:hover {
      color: $color2;
    }

    &:active {
      text-decoration: none;
    }
  }

  .bp3-dialog-header {
    box-shadow: none;
    padding: 0;
    margin-bottom: $spacing-sm;
  }

  .avatar-image {
    margin: 0 0 0 3px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-size;
  display: flex;
  z-index: 10;
  align-items: center;
  transition: background-color 0.2s ease;

  &.with-background {
    background-color: #ffffffe0;
  }

  .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    height: 57px;

    .user-notifications {
      margin: 0 30px;
    }

    .menu-btn {
      cursor: pointer;
      transition: all 0.4s;
    }
  }

  .languge-selector {
    & > .bp3-skeleton {
      height: 14px;
      width: 24px;
      margin: 0 0 0 15px;
    }
    .bp3-popover-wrapper {
      .bp3-popover-target {
        & > div {
          display: flex;
          align-items: center;
          & > img {
            display: inline-block;
            height: 14px;
            width: 24px;
            margin: 0 0 0 15px;
          }
          & > .arrow-icon {
            & > svg path {
              fill: #303030;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .header {
    .logo-col {
      display: inline-block;
    }
  }
}
