@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';
@import '~@blueprintjs/core/lib/scss/variables';

.pills-list {
  margin: $spacing-xs 0;
  .pill {
    margin: 5px;
    cursor: pointer;
  }
}

.pill {
  display: inline-block;
  margin: 5px 0;
  padding: 2px 5px;
  border-radius: 3px;
  color: $black-font;
  transition: background-color 0.2s ease;
  outline: none;
  font-weight: bold;
  min-height: 25px;
  vertical-align: middle;
  transition: all 0.3s ease;

  &[data-valid='false'] {
    text-decoration: line-through;
    filter: opacity(0.5);
  }

  &.const {
    background-color: $light-gray3;
    &:hover {
      background-color: $light-gray4;
    }
  }
  &.function {
    background-color: #fac780;

    &:hover {
      background-color: $color3-secondary;
    }
  }
  &.variable {
    background-color: #82d3cf;
    &:hover {
      background-color: #9ed9d6;
    }
  }
  .pill {
    margin: 0;
    min-height: unset;
    vertical-align: baseline;
  }
}
