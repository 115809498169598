.companies-list-widget {
  > .container-fluid {
    padding: 0;
  }

  .search-wrapper {
    display: block;
    margin: 0 0 35px 0;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    Button {
      width: 28px;
      height: 28px;
      float: left;
      border-radius: 30px;
      background-image: none;
      background: transparent;
      border: 1px solid $color2;
      color: $color2;

      svg {
        color: $color2;
      }
    }

    Button:hover {
      background: transparent;
    }
  }
}
