@import 'src/css/includes/variables';

.avatar-image {
  display: block;
  height: 44px;
  width: 44px;
  min-width: 44px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid $color2;
  background-color: $color2;
}
