@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';
.request-form {
  box-shadow: none;
  border-radius: $border-radius;
  border: 1px solid $color2;
  margin: $spacing-xs 0;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);
  }
  &.invalid {
    border-color: #f1a877;
    .collapse-header {
      .arrow-icon {
        svg path {
          fill: #f1a877;
        }
      }
    }
    .request-form-title {
      .delete-icon {
        svg path {
          fill: #f1a877;
        }
      }
    }
    &:hover {
      box-shadow: 0 3px 3.5px rgba(#f1a877, 0.3);
    }
  }
  .request-form-title {
    cursor: pointer;

    padding: $spacing-xxs 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .delete-icon {
      color: $color2;
    }

    .request-type-tag {
      margin: 0 $spacing-xs;
    }
  }
}
.request-name {
  font-weight: 700;
  font-size: 16px;
}
.request-tags {
  flex-basis: 100%;
}

.request-form-section {
  &:first-child {
    margin-top: $spacing-xxs;
  }
}

.use-global-security-toggle {
  width: 120px;
}
