@import '../../css/global';

.rounded-checkbox {
  display: none;

  & ~ label {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 2px solid $black-font;
    background-color: #ffffff;
    cursor: pointer;
    transition: border-color 100ms ease;

    position: relative;

    &::after {
      content: '';
      background-color: inherit;
      position: absolute;
      right: -5px;
      top: 0;
      width: 10px;
      height: 10px;
      user-select: none;
    }

    & > svg {
      opacity: 0;
      transition: opacity 0.6s ease;
      position: absolute;
      top: 49%;
      left: 46%;
      fill: $color2;
      transform: translate(-40%, -50%) scale(0.9);
      z-index: 2;
    }

    & > span {
      white-space: nowrap;
      margin-left: 21px;
    }
  }

  &:checked ~ label {
    border-color: $color2;

    & > svg {
      opacity: 1;
    }
  }
}
