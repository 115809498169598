@import '../../css/includes//variables';
@import '../../css/includes/spacing';

.code-editor {
  width: 100% !important;
  color: $black-font;
  font-family: monospace;
  .cm-panels-bottom {
    border: none;
  }
  .cm-panel.cm-search {
    font-size: 14px;
    font-family: $primary-font;
    padding: $spacing-xxs;
    color: $black-font;
    box-shadow: 0px -5px 5px -5px rgba(84, 173, 168, 0.6);

    label {
      font-size: inherit;
      color: inherit;
    }
    .cm-button {
      background-color: $color2;
      border: 1px solid $color2;
      color: #ffffff;
      font-size: inherit;
      border-radius: 10px;
      background-image: none;
      box-shadow: 0 3px 3.5px rgba(0, 0, 0, 0.12);
      transition: all 0.4s ease;
      font-weight: 700;

      outline: none;
      background-image: none;
      &:hover,
      &:active {
        background-color: #fff;
        border: 1px solid $color2;
        color: $color2;
        background-image: none;
      }
    }
    .cm-textfield {
      font-size: inherit;

      border: 1px solid $color2;
      background-color: #fcfcfc;
      padding: 0 21px;
      border-radius: $border-radius;
      outline: none;
      box-shadow: none !important;
      padding: 0.2em 1em;
    }
  }
}
