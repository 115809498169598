@import './variables';

@mixin shadow-card {
  border-radius: 10px;
  box-shadow: 0 3px 3.5px $shadow;
  background-color: #ffffff;
  color: inherit;
}
.custom-card {
  @include shadow-card;
}
.custom-card--border {
  border: 1px solid $color2;
}
