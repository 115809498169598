@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .app-content {
    display: flex;
    height: 100%;
    overflow: auto;
    padding-top: $header-size;
    margin-bottom: $footer-size;
  }

  & > img {
    // Background
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
