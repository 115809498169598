@import 'src/css/includes/variables';

.button-custom {
  width: 100%;
  position: relative;
  background-color: $color2;
  border: 1px solid $color2;
  color: #ffffff;
  border-radius: 10px;
  height: 35px;
  background-image: none;
  box-shadow: 0 3px 3.5px rgba(0, 0, 0, 0.12);
  transition: all 0.4s ease;
  font-size: 14px;
  font-family: 'Lato';
  font-weight: 700;
  outline: none;

  &[disabled] {
    background-color: #ffffff;
    color: $color2;
    box-shadow: none;
  }

  &.outlined {
    background-color: #ffffff;
    color: $color2;
  }

  &.small {
    width: max-content;
    min-width: 100px;
    height: 35px;
  }

  & > .bp3-spinner {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & ~ span {
      color: transparent;
    }
  }

  &:hover {
    background-color: #fff;
    border: 1px solid $color2;
    color: $color2;
  }
}
