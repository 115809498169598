.initial-setup-form {
  .dropdown {
    width: 100%;
    & > .bp3-popover-target {
      width: 100%;
      & > div > button {
        width: 100%;
        & > span {
          width: calc(100% - 50px);
        }
        & > .arrow-icon {
          margin-left: 0;
        }
      }
    }
  }
}
