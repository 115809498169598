@import '../../../css/includes/variables';

.logs-table {
}

.logs-table-header {
  margin: 0 0 20px 0;

  .bp3-card {
    // Table
    display: flex;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.25);
    background-color: #ffffff;
    padding: 0 25px;
    font-size: 16px;
    color: #303030;
    font-family: $header-font;
    font-weight: 700;

    & > div:first-of-type {
      width: 120px;
    }

    .checkbox-form-group {
      margin-bottom: 0;
    }
    .bp3-popover-target {
      .select-all-button {
        background: none;
        border: none;
        box-shadow: none;
        color: $color2;
        min-width: max-content;
        margin: 0;
        padding: 0;
        &.bp3-disabled {
          color: $gray-font;
        }
      }
    }
  }
}

.table-rows-container {
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  margin: 0;
  min-height: 785px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logs-non-ideal-state {
  padding: 100px 0;
}
.checkbox-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  label {
    margin: 0;
  }
  .arrow-icon {
    padding: 0 25px 0 0;
  }
}
.wrap-action-button {
  .action-button {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 0.5px solid #54ada8;
    box-shadow: none;

    svg path {
      fill: #808080;
    }
  }
  .action-button:first-of-type {
    margin-right: 10px;
  }
}

.search-input {
  margin-bottom: 10px;
}
