/* Fix of bugs */
.picky__input {
  height: 48px;
  min-width: 200px;
}
.picky__placeholder {
  color: black;
}
.picky__input:after {
  right: 10px;
}
