@import 'src/css/includes/variables';
@import '@blueprintjs/core/lib/scss/variables';

@mixin input-placeholder {
  font-size: 14px;
  color: $pt-text-color-disabled;
  font-family: 'Lato';
  font-weight: 300;
}

.input-styles {
  input[type='text'],
  input[type='password'],
  input[type='number'] {
    border: 1px solid $color2;
    background-color: #fcfcfc;
    height: 35px;
    padding: 0 21px;
    border-radius: $border-radius;
    outline: none;
    box-shadow: none !important;

    font-size: 14px;
    color: $black-font;
    font-family: 'Lato';
    font-weight: 300;

    transition: all 0.4s ease;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      @include input-placeholder;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      @include input-placeholder;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      @include input-placeholder;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      @include input-placeholder;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  &.bp3-intent-success {
    & input[class='bp3-input'] {
      border: 2px solid $succes-color;
      box-shadow: none;
    }
  }

  &.bp3-intent-danger {
    & > input[class='bp3-input'] {
      border: 2px solid $danger-color;
    }
  }
}

.input-form-group {
  margin: 0 0 20px;
}
