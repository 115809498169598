@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';

.parameters-list {
  .parameter-form {
    .parameter-form-title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: $spacing-xs;
    }

    margin: $spacing-xxs/2 0;
    padding: $spacing-xs $spacing-xxs;
    background-color: rgba(#9ed9d6, 0.1);

    border-radius: $border-radius/2;

    .input-form-group {
      flex-basis: 100%;
    }
    .parameter-form-controls {
      margin-left: $spacing-xxs;
      color: $color2;
      cursor: pointer;
    }
  }
}

.parameters-list-footer {
  display: flex;
  justify-content: flex-end;
  .button-custom {
    margin-right: 30px;
  }
}
