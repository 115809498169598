@import 'src/css/includes/variables';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: $footer-size;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 -3px 6px $shadow;
}
