@import '../../../../css/includes/variables';

.connector-doc-wrp {
  .swagger-ui {
    color: $black-font;
    font-family: $header-font;
    .info {
      margin: 0;
    }
    .scheme-container {
      padding: 15px 0;
    }
    .opblock-tag,
    .title,
    select {
      color: $black-font;
    }
    .servers > label {
      margin: 0;
    }
  }
}
