@import 'src/css/includes/variables';

.company-integrations {
  margin: 0 $spacing-xs $spacing-xs $spacing-xs;

  & > div[class='company-data'] {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: -25px;

    & > span:nth-of-type(1) {
      // Company name
      padding-left: 51px;
      width: 276px;
    }

    & > span:nth-of-type(2) {
      // Company logo
      margin: 0 -18px 0 0;
      background-color: #ffffff;
    }
  }
  .integrations-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > .company-data {
      margin: 0;
    }
    .add-integration-form {
      flex-basis: 100%;
    }
  }
  .integrations-wrapper {
    margin-top: $spacing - 15px;
    padding: 40px 15px 40px 40px;
    min-height: 353px;
    border-radius: 10px;
    border: 1px solid $color2;
    box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);

    .integrations-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .integration-filter-tags {
        margin: 10px 0;
        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        .custom-tag {
          margin: 5px;
        }
      }
    }

    .bp3-input {
      padding-left: 30px;
    }
  }

  .compIntegration {
    max-height: 500px;

    .sub-title {
      margin: $spacing-sm 0;
    }

    .bp3-non-ideal-state {
      padding-right: 25px;
    }
  }

  .integration {
    &.teaser {
      position: relative;
      margin: 0 0 $spacing-sm 0;
      border-radius: 10px;
      border: 1px solid $color2;

      .wrapper {
        display: flex;
        align-items: center;
        min-height: 70px;
        & > div:first-of-type {
          padding-right: 0;
        }
        & > div:nth-of-type(2) {
          padding-left: 0;
        }
        .integration-nid {
          font-size: 0.8em;
          font-weight: bold;
          color: $color2;
          position: absolute;
          top: 10px;
          left: 10px;
          opacity: 0.1;
          transition: all 0.4s;
        }
        .integration-tags {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -5px;
          .integration-tag {
            padding: 5px;
            min-height: auto;
            margin: 5px;
          }
        }
        .integration-switch {
          margin: 0;
          outline: none;

          input:checked ~ .bp3-control-indicator::before {
            left: calc(100% - 26px);
            width: 21px;
            height: 21px;
            transform: translateY(-2px);
            box-shadow: 0 0 3.5px rgba(0, 0, 0, 0.22);
          }

          input:checked ~ .bp3-control-indicator {
            background-color: #54ada8dd;
          }

          & > span {
            width: 61px;
            height: 29px;
            outline: none;
            display: flex;
            align-items: center;
            font-family: 'Arial';
            font-weight: bold;
            background-color: #54ada850;

            &::before {
              width: 23px;
              height: 23px;
              left: 1px;
              transform: translateY(-2px);
              box-shadow: 0 0 3.5px rgba(0, 0, 0, 0.16);
              transition: all 300ms ease;
            }

            & > .bp3-control-indicator-child {
              text-transform: uppercase;
              font-size: 10px;
              color: #fff;
              margin: 0 2px;

              &:first-of-type {
                margin: 0 12px;
              }
            }
          }
        }

        .integration-actions {
          opacity: 1;
          transform: rotate(90deg);
          transition: all 0.4s;
          text-align: center;

          svg path {
            fill: $color2;
          }
        }
      }

      &:hover {
        box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);
        opacity: 1;

        .integration-actions,
        .integration-nid {
          opacity: 1;
        }
      }

      &.teaser-disabled {
        opacity: 0.5;
        transition: opacity 0.4s linear;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.companies-wrapper {
  margin: 0;
}

.integrations-list > div {
  .track-horizontal {
    display: none;
  }
}

.integrations-list-item-skeleton {
  height: 112px;
  border-radius: 10px;
  margin-bottom: 30px;
}
