.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #ffffffff;
  transition: background-color 0.4s ease;

  img {
    width: 100px;
    height: 100px;
    background-size: cover;
  }

  &.shadow {
    background-color: #ffffff90;
  }
}
