.auth-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: -4%;
    right: -10%;

    img {
      height: 100%;
      width: auto;
    }
  }

  .header {
    .right-side {
      .avatar-image,
      .menu-btn {
        display: none;
      }
    }
  }
}
