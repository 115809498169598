@import 'css/includes/variables';
@import 'css/includes/spacing';

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

.bp3-tab-panel {
  width: 100%;
}

.app-login-container {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.bp3-non-ideal-state {
  svg path {
    fill: $color2;
  }
}

.bp3-control:hover input:checked ~ .bp3-control-indicator {
  // Fix checkbox hover state color
  background-color: grey !important;
}

.form-group {
  margin-bottom: $spacing-xs;
}
