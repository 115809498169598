@import '../../css/global';
@import '../../css/includes/form';

.container-auth-form {
  background-color: #ffffff;
  padding: $spacing;
  border-radius: 10px;
  border: 1px solid $color2;
  box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.3);

  .custom-link {
    display: block;
    margin: 19px 0 0 0;
    font-size: 14px;
    color: $black-font;
    text-align: center;
    font-family: 'Lato';
    font-weight: 700;

    > span {
      cursor: pointer;
    }
  }

  a {
    color: $color2;
  }

  .bp3-form-group {
    margin-bottom: $spacing-xs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
