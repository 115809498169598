.main-wrapper {
  display: flex;
  height: 100vh;
  padding-top: $header-size;
}

.main-row {
  padding-top: $spacing-xs;
  display: flex;
  align-items: stretch;
  height: 100%;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.background-picture {
  position: absolute;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  text-align: right;
}

body {
  background: $color1;
  font-family: $primary-font;
  color: $black-font;
}

h1,
h2,
h3,
h4,
h5,
.bp3-heading {
  font-family: $header-font;
  font-weight: bold;
  color: $black-font;
}

.integration-icon {
  img {
    max-width: 100%;
    max-height: 45px;
    width: auto;
    min-width: 35px;
  }
}

a {
  color: #2b95d6;
  &:hover {
    color: #106ba3;
  }
}
