.error-message {
  h1 {
    font-size: 20px;
    color: #a82a2a;
    margin: 0 0 40px 0;
  }

  p {
    font-size: 16px;

    strong {
      font-weight: bold;
    }
  }
}
