@import '../../css/includes/variables';

.company-logo-background {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 103px;
  height: 103px;
  border-radius: 50%;
  padding: 1px 2px 0 1px;
  background-color: $color2;

  & > img {
    margin: 0;
    border: none;
  }
}
