@import '../../../../css/includes/variables';
@import '../../../../css/includes/spacing';

.log-item {
  // Table Row
  display: flex;
  align-items: center;
  //justify-content: center;
  //height: 65px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 0;
  box-shadow: none;
  padding: 18px 10px 18px 20px;
  border-bottom: 2px solid #54ada875;
  transition: all 0.2s ease;

  & > div {
    padding: 0;
  }

  & > div:nth-of-type(1) {
    display: inline-flex;
    width: 100px;
  }

  & > div:nth-of-type(4) {
    & > span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > div:nth-of-type(3) {
    overflow-wrap: break-word;
  }

  & > div:nth-of-type(2) {
    span {
      margin-right: 10px;
    }
  }

  div > label {
    margin: 0;
  }

  &.opened {
    border-color: #ffffff;
    box-shadow: 0 6px 3.5px -3.5px rgba(84, 173, 168, 0.4);
  }

  .logs-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      cursor: pointer;

      &:not(:last-of-type) {
        margin-right: $spacing-xxs;
      }
    }
  }
}
.logs-id {
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  color: $color2;
  position: absolute;
  right: 40px;
  top: 20px;
  opacity: 0.1;
  transition: all 0.4s;
}
.collapse-custom {
  .bp3-collapse-body {
    padding: $spacing;
    position: relative;
    border-bottom: 2px solid #54ada875;
    &:hover {
      .logs-id {
        opacity: 1;
      }
    }
  }
}

.collapse-control-button {
  padding: 0 25px 0 0;
  &.opened {
    svg {
      transform: rotate(180deg);
    }
  }
}
