@import '../../css/includes/variables';

.company-data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  span:nth-of-type(1) {
    // Company name
    display: block;
    height: 50px;
    width: 190px;
    line-height: 50px;
    padding-left: 20px;
    background-color: $color2;
    border-radius: 10px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 14px;
    color: #ffffff;
    font-family: 'Lato';
    font-weight: 700;
  }

  span:nth-of-type(2) {
    // Company logo
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
    border-radius: 50%;
    z-index: 1;
    margin-left: -18px;
    background-color: #f5fafa;

    img,
    .bp3-skeleton {
      width: 76px;
      height: 76px;
      border-radius: 50%;
    }
  }
}
