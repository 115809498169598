.company-list-teaser-medium {
}

.companies-list {
}

.iconAccount {
  position: absolute;
  bottom: 0;
  left: -85%;
  width: 65%;
}

.background-picture {
  text-align: left;
  right: 300px;
  top: -250px;
  overflow: visible;

  img {
    position: absolute;
    left: -30%;
    top: 10%;
  }
}
