@import '../../css/includes/variables';

.arrow-icon {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  & > svg {
    transition: all 0.4s ease;

    path {
      fill: $color2;
    }
  }

  &.disabled {
    cursor: default;

    & > svg {
      path {
        fill: $color2-secondary;
      }
    }
  }
}
