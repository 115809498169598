@import '../../../../css/includes/spacing';
@import '../../../../css/includes/variables';

.connector-teaser {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-sm;
  margin: $spacing-xxs;
  border-radius: $border-radius;
  border: 1px solid $color2;
  box-shadow: none;
  transition: all 0.4s;
  &.bp3-card.bp3-interactive:hover {
    box-shadow: 0 3px 3.5px $shadow;
    .connector-nid {
      opacity: 1;
    }
  }
  .connector-actions {
    color: $color2;
    .bp3-icon-more {
      transform: rotate(90deg);
    }
  }
  .connector-nid {
    position: absolute;
    top: $spacing-xs;
    right: $spacing-xs + 8px;
    font-size: 0.8em;
    font-weight: bold;
    opacity: 0.1;
    transition: all 0.4s;
  }
  .connector-title {
    width: 75%;
    padding: 0 $spacing-xxs;
  }
}

.connectors-list-skeleton {
  height: 122px;
  border-radius: $border-radius;
  margin: $spacing-xxs;
}
