.form-actions {
  position: absolute;
  display: inline-flex;
  right: 2%;
  bottom: 0;
  transform: translateY(50%);
  z-index: 1;

  > *:not(last-of-type) {
    margin-right: 18px;
  }
}
