$spacing: 40px;

$spacing-xxs: 0.25 * $spacing;
$spacing-xs: 0.5 * $spacing;
$spacing-sm: 0.75 * $spacing;
$spacing-md: 1.25 * $spacing;
$spacing-lg: 2 * $spacing;
$spacing-xl: 3.25 * $spacing;
$spacing-xxl: 3.25 * $spacing;
$spacing-xxxl: 5.25 * $spacing;

$list-margins: 'top', 'left', 'right', 'bottom';

@each $side in $list-margins {
  // Margins
  .margin-#{$side}-xxs {
    margin-#{$side}: $spacing-xxs;
  }
  .margin-#{$side}-xs {
    margin-#{$side}: $spacing-xs;
  }
  .margin-#{$side}-sm {
    margin-#{$side}: $spacing-sm;
  }
  .margin-#{$side} {
    margin-#{$side}: $spacing;
  }
  .margin-#{$side}-md {
    margin-#{$side}: $spacing-md;
  }
  .margin-#{$side}-lg {
    margin-#{$side}: $spacing-lg;
  }
  .margin-#{$side}-xl {
    margin-#{$side}: $spacing-xl;
  }
  .margin-#{$side}-xxl {
    margin-#{$side}: $spacing-xxl;
  }
  .margin-#{$side}-xxxl {
    margin-#{$side}: $spacing-xxxl;
  }

  // Paddings
  .padding-#{$side}-xxs {
    padding-#{$side}: $spacing-xxs;
  }
  .padding-#{$side}-xs {
    padding-#{$side}: $spacing-xs;
  }
  .padding-#{$side}-sm {
    padding-#{$side}: $spacing-sm;
  }
  .padding-#{$side} {
    padding-#{$side}: $spacing;
  }
  .padding-#{$side}-md {
    padding-#{$side}: $spacing-md;
  }
  .padding-#{$side}-lg {
    padding-#{$side}: $spacing-lg;
  }
  .padding-#{$side}-xl {
    padding-#{$side}: $spacing-xl;
  }
  .padding-#{$side}-xxl {
    padding-#{$side}: $spacing-xxl;
  }
  .padding-#{$side}-xxxl {
    padding-#{$side}: $spacing-xxxl;
  }
}

.margin-xxs {
  margin: $spacing-xxs;
}
.margin-xs {
  margin: $spacing-xs;
}
.margin-sm {
  margin: $spacing-sm;
}
.margin {
  margin: $spacing;
}
.margin-md {
  margin: $spacing-md;
}
.margin-lg {
  margin: $spacing-lg;
}
.margin-xl {
  margin: $spacing-xl;
}
.margin-xxl {
  margin: $spacing-xxl;
}
.margin-xxxl {
  margin: $spacing-xxxl;
}

.padding-xxs {
  padding: $spacing-xxs;
}
.padding-xs {
  padding: $spacing-xs;
}
.padding-sm {
  padding: $spacing-sm;
}
.padding {
  padding: $spacing;
}
.padding-md {
  padding: $spacing-md;
}
.padding-lg {
  padding: $spacing-lg;
}
.padding-xl {
  padding: $spacing-xl;
}
.padding-xxl {
  padding: $spacing-xxl;
}
.padding-xxxl {
  padding: $spacing-xxxl;
}

// Absolute

@each $side in $list-margins {
  .#{$side}-xxs {
    #{$side}: $spacing-xxs;
  }
  .#{$side}-xs {
    #{$side}: $spacing-xs;
  }
  .#{$side}-sm {
    #{$side}: $spacing-sm;
  }
  .#{$side} {
    #{$side}: $spacing;
  }
  .#{$side}-md {
    #{$side}: $spacing-md;
  }
  .#{$side}-lg {
    #{$side}: $spacing-lg;
  }
  .#{$side}-xl {
    #{$side}: $spacing-xl;
  }
  .#{$side}-xxl {
    #{$side}: $spacing-xxl;
  }
  .#{$side}-xxxl {
    #{$side}: $spacing-xxxl;
  }
}
