@import 'src/css/includes/variables';

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex-basis: 390px;
  min-height: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid $color2;
  box-shadow: 0 3px 3.5px rgba(84, 173, 168, 0.25);

  .card-content {
    width: 100%;
  }

  .settings-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;

    svg path {
      fill: $color2;
    }
  }

  .intent-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    &.success {
      background-color: #54ada8;
      box-shadow: 0 0 15px rgba(84, 173, 168, 0.5);
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 138px;
    color: $color2;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 34px;
    text-align: center;
  }

  .card-error-message {
    color: #a82a2a;
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
    width: 220px;
    word-break: break-word;

    span {
      font-weight: bold;
      font-size: 16px;
      color: $black-font;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.card-skeleton {
  flex-basis: 390px;
  height: 138px;
  // margin: 1px;
  border-radius: 10px;
}

FORM {
  display: grid;
  height: 100%;
  padding-left: 25px;
}

.form-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 40px 20px 20px 20px;
}

.form-actions {
  margin-top: auto;
  position: static;
  padding-bottom: 25px;
  padding-left: 20px;
  transform: translateY(0);
  margin-left: auto;
  margin-right: auto;
}

.bp3-heading > div {
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: $color2;
  font-size: 26px;
  margin-top: 10px;
  font-weight: 900;
}

.component-config-drawer {
  width: 30% !important;
}
