@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';
@import '~@blueprintjs/core/lib/scss/variables';

.edit-mode-button {
  color: $gray-font;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: color 0.2s ease-in-out;
  &.active {
    color: $color2;
  }
}

.edit-function-buttons {
  position: absolute;
  left: calc(100% - 60px);
  z-index: 10;
  top: 20px;
  .bp3-icon.bp3-icon-confirm {
    color: $color2;
  }
  .bp3-icon.bp3-icon-arrow-left {
    color: $danger-color;
    margin-right: 5px;
  }
}
