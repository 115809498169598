@import 'src/css/includes/variables';
@import 'src/css/includes/spacing';

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing-xxs 0;
  padding: $spacing-xxs;
  border-bottom: 2px solid #54ada875;
  border-top: 2px solid #54ada875;
  transition: background-color 0.3s ease;
  flex-wrap: wrap;
  &:hover {
    background-color: rgba(#9ed9d6, 0.1);
  }

  .list-item-icon {
    margin-right: 5px;
  }

  .list-item-controls > * {
    color: $color2;
    cursor: pointer;
    margin: $spacing-xxs;
  }

  &.warning {
    .list-item-controls > * {
      color: #f1a877;
    }
    border-bottom: 2px solid rgba(241, 168, 119, 0.5);
    border-top: 2px solid rgba(241, 168, 119, 0.5);
    &:hover {
      background-color: rgba(#ffd5b6, 0.1);
    }
  }

  .list-item-title {
    display: flex;
    align-items: center;
    font-family: $header-font;
    font-weight: bold;
  }

  .list-item-controls {
    .bp3-icon {
      transition: opacity 0.3s ease;
      opacity: 1;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
